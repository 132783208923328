import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';
import { generateDays, generateYears, months } from '../SelectOptions';
import { GetAddressType } from '@/services/address.services';
import { addressFormConfig } from './AddressConfig';
const labelColor = '#222';

export const addressPickerEircodeName = 'Eircode ';

export const contactDetailsFormInputs: IFormInput[] = [
  {
    inputType: 'inputField',
    label: 'Name',
    name: 'firstName',
    fullWidth: false,
    lengthValidationSize: 2,
    placeholder: 'First name',
    requiredField: true,
    validationType: 'length',
    dataType: 'alpha',
  },
  {
    inputType: 'inputField',
    name: 'lastName',
    label: 'Last Name',
    fullWidth: false,
    lengthValidationSize: 2,
    placeholder: 'Last name',
    requiredField: true,
    validationType: 'length',
    labelType: 'transparent',
    dataType: 'alpha',
  },
  {
    inputType: 'select',
    name: 'daySelect',
    label: 'Date of birth',
    fullWidth: false,
    placeholder: 'Day',
    validationType: 'date',
    requiredField: true,
    customErrorMessage: 'Please select a valid date.',
    customValue: 'Day',
    options: generateDays(1, 2006),
    customWidth: '33%',
  },
  {
    inputType: 'select',
    name: 'monthSelect',
    label: 'month',
    fullWidth: false,
    placeholder: 'Month',
    requiredField: true,
    validationType: 'date',
    labelType: 'transparent',
    customWidth: '33%',
    customValue: 'Month',
    options: months,
  },
  {
    inputType: 'select',
    name: 'yearSelect',
    label: 'year',
    fullWidth: false,
    placeholder: 'Year',
    requiredField: true,
    validationType: 'date',
    labelType: 'transparent',
    customWidth: '33%',
    customValue: 'Year',
    options: generateYears(1950, 2006),
  },
  {
    inputType: 'select',
    name: 'numberPrefix',
    label: 'Phone number',
    placeholder: 'Enter your number',
    requiredField: true,
    customValue: '353',
    customWidth: '33%',
    options: [
      {
        text: '+353 (Ireland)',
        value: '353',
      },
    ],
  },
  {
    inputType: 'inputField',
    name: 'Contact Phone',
    label: 'contactphone',
    placeholder: 'XX XXX XXXX',
    requiredField: true,
    dataType: 'number',
    validationType: 'regex',
    regex: /^08[3-9][0-9]{7}$/,
    customErrorMessage: 'Please enter a valid phone number',
    lengthValidationSize: 6,
    lengthValidationMaxSize: 9,
    triggerFieldsValidation: ['Date of birth', 'month', 'year'],
    customWidth: '66%',
    labelType: 'transparent',
  },
  {
    inputType: 'inputField',
    name: 'emailAddress',
    label: 'Email address',
    fullWidth: true,
    placeholder: 'Enter your email',
    requiredField: true,
    validationType: 'email',
    helpText: 'This email will be used to login to My Vodafone.',
    customErrorMessage: 'Please enter a valid e-mail address',
    triggerFieldsValidation: ['Date of birth', 'month', 'year'],
    dataType: 'email',
  },
  {
    inputType: 'inputField',
    name: 'confirmEmailAddress',
    label: 'Confirm email address',
    fullWidth: true,
    placeholder: 'Enter your email',
    requiredField: true,
    validationType: 'confirmEmail',

    dataType: 'email',
  },
  {
    inputType: 'radioList',
    name: 'AddressOption',
    label: 'Address or Eircode search option',
    fullWidth: true,
    customLabelSize: '18px',
    radioOptions: [
      { label: 'Address', value: 'TEXT' as GetAddressType },
      { label: 'Eircode', value: 'POSTAL_CODE' as GetAddressType },
    ],
  },
  {
    inputType: 'addressPicker',
    name: 'address',
    label: 'Search Your Address',
    alternativeLabel: 'Select your address',
    customErrorMessage: 'Please enter a valid address',
    fullWidth: true,
    disabled: true,
    placeholder: 'Type here your address',
    helpText: 'Search for your address and select from the results',
    validationType: 'length',
    dataType: 'alphanum-space',
    hidden: true,
  },
  {
    inputType: 'addressPicker',
    name: 'eircodeOption',
    label: addressPickerEircodeName,
    helpText: 'Start typing your Eircode and select',
    fullWidth: true,
    disabled: true,
    placeholder: 'Search Eircode',
    validationType: 'postCode',
    dataType: 'alphanum',
    hidden: true,
  },
  {
    inputType: 'checkbox',
    name: 'addressCheckbox',
    label: 'Enter your address manually',
    customFontSize: '18px',
    fullWidth: true,
    labelType: 'hidden',
    validationType: 'length',
    checkboxOptions: [{ label: 'Enter your address manually', value: 'addressManually' }],
  },
  ...addressFormConfig,
];
