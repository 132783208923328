import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { CreditVettingFormProps } from './CreditVettingForm.types';
import {
  ElementWrapper,
  CVFDescription,
  CreditVettingWrapper,
  InlineNotificationWrapper,
} from './CreditVettingForm.styles';
import { Spacer } from '@/components/Global/RegisterAccount/RegisterAccount.styles';
import { CreditVettingPayload } from '@/services/product.services';
import { SelectInputWithLabel } from '@legacy-vfuk/core-select-input-with-label';
import InLineNotification from '@uk-source-web/inline-notification';

interface CreditVettingFormDateInput {
  date: Date | 'error' | null;
  dateString: string;
  value: string;
}

// Define the interface for the exposed methods
export interface CreditVettingFormRef {
  submit: () => void;
}

export const CreditVettingForm = forwardRef<CreditVettingFormRef, CreditVettingFormProps>(
  (
    { description, invertTheme = true, onSubmit, onChange, inlineNotificationVisible, inlineNotificationMessage },
    ref
  ) => {
    const [bankStartDate, setBankStartDate] = useState<CreditVettingFormDateInput | null>(null);
    const [businessStartDate, setBusinessStartDate] = useState<CreditVettingFormDateInput | null>(null);

    const handleDateChange = (value: string, isBusiness: boolean) => {
      const date = new Date();
      const startDate = new Date();

      const isMonths = value.includes('m');
      const isYears = value.includes('y');

      if (isMonths) {
        const months = parseInt(value.replace('m', ''));
        startDate.setMonth(date.getMonth() - months);
      } else if (isYears) {
        const years = parseInt(value.replace('y', ''));
        startDate.setFullYear(date.getFullYear() - years);
      }

      const dateString = startDate.toISOString().split('T')[0];
      if (isBusiness) {
        setBusinessStartDate({
          date: startDate,
          dateString: dateString,
          value: value,
        });
      } else {
        setBankStartDate({
          date: startDate,
          dateString: dateString,
          value: value,
        });
      }
    };

    useEffect(() => {
      onChange?.({
        bankStartDay: bankStartDate?.dateString || '',
        businessStartDate: businessStartDate?.dateString || '',
      });
    }, [bankStartDate, businessStartDate]);

    const handleSubmitClick = () => {
      if (bankStartDate == null || businessStartDate == null) {
        return;
      }
      const payload: CreditVettingPayload = {
        bankStartDay: bankStartDate?.dateString || '',
        businessStartDate: businessStartDate?.dateString || '',
      };
      onSubmit?.(payload);
    };

    useImperativeHandle(ref, () => ({
      submit: handleSubmitClick,
    }));

    return (
      <CreditVettingWrapper invertTheme={invertTheme}>
        <CVFDescription invertTheme={invertTheme}>{description}</CVFDescription>
        {inlineNotificationVisible ? (
          <>
            <InlineNotificationWrapper>
              <InLineNotification
                appearance="secondary"
                text={inlineNotificationMessage || ''}
                state="warn"
                id="cvf-inline-notification"
              />
            </InlineNotificationWrapper>
            <Spacer w={100} h={30} />
          </>
        ) : (
          <Spacer w={100} h={50} />
        )}

        <ElementWrapper invertTheme={invertTheme}>
          <SelectInputWithLabel
            fieldWrapper={{
              label: 'How long have you been with your bank?',
              width: 'full',
            }}
            selectInput={{
              id: 'bank-start-date',
              name: 'bankStartDate',
              placeholder: {
                text: 'Please select',
                value: 'Please select',
              },
              options: [
                { text: 'Less than a year', value: '11m' },
                { text: '1-3 years', value: '3y' },
                { text: '3-5 years', value: '5y' },
                { text: '5+ years', value: '16y' },
              ],
              onChange: e => handleDateChange(e.target.value, false),
              value: bankStartDate?.value || 'Please select',
            }}
          />
        </ElementWrapper>
        <Spacer w={100} h={15} />

        <ElementWrapper invertTheme={invertTheme}>
          <SelectInputWithLabel
            fieldWrapper={{
              label: 'When was your company created?',
              width: 'full',
            }}
            selectInput={{
              id: 'business-start-date',
              name: 'businessStartDate',
              placeholder: {
                text: 'Please select',
                value: 'Please select',
              },
              options: [
                { text: 'Less than a year', value: '11m' },
                { text: '1-3 years', value: '3y' },
                { text: '3-5 years', value: '5y' },
                { text: '5+ years', value: '7y' },
              ],
              onChange: e => handleDateChange(e.target.value, true),
              value: businessStartDate?.value || 'Please select',
            }}
          />
        </ElementWrapper>
        <Spacer w={100} h={50} />
      </CreditVettingWrapper>
    );
  }
);
