import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from '@uk-source-web/button';
import { FullWidthInput, Grid, Input, ModalContent, ModalHeader, ModalOverlay, Select, Buttons, InputDiv, InputDivWide, ErrorMessage, ErrorInput, Asterisk, CloseButton } from "./EditMicrosoftDataForm.styles";
import { getCounties } from "@/services/address.services";
import axios, { AxiosResponse } from "axios";




interface EditMicrosoftDataFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: Record<string, string>) => void;
  onErrors: (newErrors: Record<string, boolean>) => void;
  setMicrosoftAdditionalData: (data: string) => void;
  continueBtn: Dispatch<SetStateAction<boolean>>
  accountId:string
}
interface County {
  name: string; // Adjust this based on the actual data structure
}

type GetCountiesResponse = County[];


const EditMicrosoftDataForm: React.FC<EditMicrosoftDataFormProps> = ({ isOpen, onClose, onSubmit, onErrors, setMicrosoftAdditionalData, continueBtn,accountId }) => {
  if (!isOpen) return null;
  const [counties, setCounties] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //getting county
  useEffect(() => {
    const fetchCounties = async () => {
      try {
        const response = await getCounties("IE");


        if ((response as AxiosResponse).data) {
          const countyNames = (response as AxiosResponse<GetCountiesResponse>).data.map(
            (county: { name: string }) => county.name
          );
          setCounties(countyNames);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (axios.isAxiosError(error)) {
          console.error("Error fetching counties:", error.message);
        } else {
          console.error("An unexpected error occurred:", error);
        }
      }
    };

    fetchCounties();
  }, []);


  const [optionalData, setOptionalData] = useState({

    businessName: "",
    county: "",
    city: "",
    address: '',
    address2: '',


  })

  const handleOptionalData = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target
    const sanitizedValue = sanitizeInput(value);
    setOptionalData((prev) => {
      const updatedData = { ...prev, [name]: sanitizedValue }


      localStorage.setItem(`${accountId}_optionalData`, JSON.stringify(updatedData)); // Save every change
      return updatedData;
    })


  }


  const [formData, setFormData] = useState({
    tenantName: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',

    zipCode: "",
    country: '',

    phone: '',
    registrationNumber: '',
    consentFirstName: '',
    consentLastName: '',
    consentEmail: '',
    consentPhone: ''
  });




  const [errors, setErrors] = useState<Record<string, boolean>>({});

  //sanitizing
  const sanitizeInput = (value: string) => {
    return value.replace(/[<>/{}]/g, "");
  };

  //getting data and state stored in localstorage

  useEffect(() => {
    // Step 1: Check if currentAccountId is already defined in localStorage
    let prevAccountId = localStorage.getItem("currentAccountId");
  
    if (!prevAccountId) {
      // Set a new accountId 
      prevAccountId = "default"; 
      localStorage.setItem("currentAccountId", prevAccountId);
    }
  
    // Step 2: If accountId is different, clean up old data and update
    if (prevAccountId !== accountId) {
      if (prevAccountId) {
        // Clean up previous account data in localStorage
        localStorage.removeItem(`${prevAccountId}_formData`);
        localStorage.removeItem(`${prevAccountId}_formErrors`);
        localStorage.removeItem(`${prevAccountId}_optionalData`);
        localStorage.removeItem(`${prevAccountId}_microsoftAdditionalData`);
      }
      // Set the current account ID to localStorage
      localStorage.setItem("currentAccountId", accountId);
    }
  
    // Step 3: Fetch and update data for the current account
    const savedData = localStorage.getItem(`${accountId}_formData`);
    const savedErrors = localStorage.getItem(`${accountId}_formErrors`);
    const optionalData = localStorage.getItem(`${accountId}_optionalData`);
  
    // Step 4: Update state if data is found
    try {
      if (savedData) {
        setFormData(JSON.parse(savedData));
      }
      if (savedErrors) {
        setErrors(JSON.parse(savedErrors));
      }
      if (optionalData) {
        setOptionalData(JSON.parse(optionalData));
      }
    } catch (error) {
      console.error("Error parsing data from localStorage:", error);
    }
  }, [isOpen, accountId]);
  



  //getting user input after sanitization and set them in localstorage
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
  
    // Sanitize input
    const sanitizedValue = sanitizeInput(value);
  
    // Validate firstName and lastName
    if (name === "firstName" || name === "lastName") {
      const nameRegex = /^[A-Za-z\s]*$/; // Allows only letters and spaces
      if (!nameRegex.test(sanitizedValue)) {
        setErrors((prev) => ({
          ...prev,
          [name]: true, // Set error for invalid input
        }));
        return; // Reject invalid input
      }
    }
  
    // Update state
    setFormData((prev) => {
      const updatedData = { ...prev, [name]: sanitizedValue };
      localStorage.setItem(`${accountId}_formData`, JSON.stringify(updatedData)); // Save every change
      return updatedData;
    });
  
    // Clear error if input is valid
    setErrors((prev) => ({
      ...prev,
      [name]: false, // Clear error for valid input
    }));
  };
  



  const handleSubmit = () => {
    const newErrors: Record<string, boolean> = {};


    Object.keys(formData).forEach((field) => {
      const value = (formData[field as keyof typeof formData] || '').trim();

      if (!value) {
        newErrors[field] = true;
      }
    });

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const microsoftEmailRegex = /@microsoft\.com$/i; // Matches "@Microsoft.com" case-insensitively
    if (formData.email) {
      if (!emailRegex.test(formData.email)) {
        newErrors.email = true; // Invalid email format
      } else if (microsoftEmailRegex.test(formData.email)) {
        newErrors.email = true; // Rejected Microsoft emails
      }
    }

    // Phone validation: only numbers and optional length check
    const phoneRegex = /^\+353\d{7,9}$/;
    if (formData.phone && !phoneRegex.test(formData.phone)) {
      newErrors.phone = true;
    }


    if (Object.keys(newErrors).length === 0) {
      localStorage.setItem(`${accountId}_microsoftAdditionalData`, "success");
      setMicrosoftAdditionalData("success");
      onSubmit(formData);
      localStorage.removeItem(`${accountId}_formErrors`);
      continueBtn(false);
      onErrors({});
    } else {
      setErrors(newErrors);
      onErrors(newErrors);
      localStorage.setItem(`${accountId}_formErrors`, JSON.stringify(newErrors));
      localStorage.setItem(`${accountId}_microsoftAdditionalData`, "error");
      continueBtn(true);
      setMicrosoftAdditionalData("error");
      onClose();
    }
  };





  return (
    <ModalOverlay>


      <ModalContent>
        <ModalHeader>
          <p>Additional Information</p>
          <CloseButton onClick={onClose}>x</CloseButton>
        </ModalHeader>

        <Grid>
          <InputDiv>
            <label htmlFor="tenantName">Microsoft tenant name <Asterisk>*</Asterisk> </label>
            {errors.tenantName ? (
              <ErrorInput name="tenantName" placeholder="tenantName" value={formData.tenantName} onChange={handleChange} />
            ) : (
              <Input name="tenantName" placeholder="tenantName" value={formData.tenantName} onChange={handleChange} />
            )}
            {errors.tenantName && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDiv>
            <label htmlFor="username">User Prinicple name <Asterisk>*</Asterisk></label>
            {errors.username ? (
              <ErrorInput name="username" placeholder="username" value={formData.username} onChange={handleChange} />
            ) : (
              <Input name="username" placeholder="username" value={formData.username} onChange={handleChange} />
            )}
            {errors.username && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDivWide>
            <label htmlFor="email">Email <Asterisk>*</Asterisk></label>
            {errors.email ? (
              <ErrorInput name="email" placeholder="email" value={formData.email} onChange={handleChange} />
            ) : (
              <Input name="email" placeholder="email" value={formData.email} onChange={handleChange} />
            )}
            {errors.email && <ErrorMessage>Please enter a valid email address and does not have @Microsoft.com in it</ErrorMessage>}

          </InputDivWide>



          <InputDiv>
            <label htmlFor="firstName">First name <Asterisk>*</Asterisk></label>
            {errors.firstName ? (
              <ErrorInput name="firstName" placeholder="firstName" value={formData.firstName} onChange={handleChange} />
            ) : (
              <Input name="firstName" placeholder="firstName" value={formData.firstName} onChange={handleChange} />
            )}
            {errors.firstName && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDiv>
            <label htmlFor="lastName">Last name <Asterisk>*</Asterisk></label>
            {errors.lastName ? (
              <ErrorInput name="lastName" placeholder="lastName" value={formData.lastName} onChange={handleChange} />
            ) : (
              <Input name="lastName" placeholder="lastName" value={formData.lastName} onChange={handleChange} />
            )}
            {errors.lastName && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDivWide>
            <label htmlFor="businessName">Business Name <Asterisk>*</Asterisk></label>
            <FullWidthInput name="businessName" placeholder="Your Business Name" value={optionalData.businessName} onChange={handleOptionalData} />
            {errors.address && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>
          <InputDivWide>
            <label htmlFor="address">Address </label>
            <FullWidthInput name="address" placeholder="Your address" value={optionalData.address} onChange={handleOptionalData} />
            {errors.address && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>
          <InputDivWide>
            <label htmlFor="address2">Address 2</label>
            <FullWidthInput name="address2" placeholder="Your other address" value={optionalData.address2} onChange={handleOptionalData} />
            {errors.address && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>

          <InputDiv>
            <label htmlFor="zipCode">ZipCode <Asterisk>*</Asterisk></label>
            {errors.zipCode ? (
              <ErrorInput name="zipCode" placeholder="zipCode" value={formData.zipCode} onChange={handleChange} />
            ) : (
              <Input name="zipCode" placeholder="zipCode" value={formData.zipCode} onChange={handleChange} />
            )}
            {errors.zipCode && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDiv>
            <label htmlFor="city">City </label>



            <Input name="city" placeholder="city" value={optionalData.city} onChange={handleOptionalData} />


          </InputDiv>

          <InputDiv>
            <label htmlFor="country">Country <Asterisk>*</Asterisk></label>
            <Select id="country" name="country" value={formData.country} style={{ border: errors.country ? '1px solid red' : '' }} onChange={handleChange}>
              <option value="">Select Country</option>
              <option value="Ireland">Ireland</option>
              {/* Add other countries if needed */}
            </Select>
          </InputDiv>


          <InputDiv>
            <label htmlFor="county">County <Asterisk>*</Asterisk></label>
            {loading ? (
              <div>Loading counties...</div>
            ) : (
              <Select id="county" name="county" onChange={handleOptionalData} value={optionalData.county}>
                <option value="">Select County</option>
                {counties.map((county, index) => (
                  <option key={index} value={county}>{county}</option>
                ))}
              </Select>
            )}
          </InputDiv>


          <InputDivWide>
            <label htmlFor="phone">Phone Number <Asterisk>*</Asterisk></label>
            <FullWidthInput name="phone" placeholder="Phone Number" value={formData.phone} error={errors.phone} onChange={handleChange} />
            {errors.phone && <ErrorMessage>Please enter a valid phone number (numbers only)</ErrorMessage>}
          </InputDivWide>

          <InputDivWide>
            <label htmlFor="registrationNumber">Organization registration number <Asterisk>*</Asterisk></label>
            <FullWidthInput name="registrationNumber" placeholder="Organization registration number" error={errors.registrationNumber} value={formData.registrationNumber} onChange={handleChange} />
            {errors.registrationNumber && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>

          <InputDiv>
            <label htmlFor="consentFirstName">Consent user - First name <Asterisk>*</Asterisk></label>
            {errors.consentFirstName ? (
              <ErrorInput name="consentFirstName" placeholder="consentFirstName" value={formData.consentFirstName} onChange={handleChange} />
            ) : (
              <Input name="consentFirstName" placeholder="consentFirstName" value={formData.consentFirstName} onChange={handleChange} />
            )}
            {errors.consentFirstName && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDiv>
            <label htmlFor="consentLastName">Consent user - Last name <Asterisk>*</Asterisk></label>
            {errors.consentLastName ? (
              <ErrorInput name="consentLastName" placeholder="consentLastName" value={formData.consentLastName} onChange={handleChange} />
            ) : (
              <Input name="consentLastName" placeholder="consentLastName" value={formData.consentLastName} onChange={handleChange} />
            )}
            {errors.consentLastName && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDiv>

          <InputDivWide>
            <label htmlFor="consentEmail">Consent User Email <Asterisk>*</Asterisk></label>
            <FullWidthInput name="consentEmail" placeholder="Consent User Email" value={formData.consentEmail} error={errors.consentEmail} onChange={handleChange} />
            {errors.consentEmail && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>

          <InputDivWide>
            <label htmlFor="consentPhone">Consent User Phone Number <Asterisk>*</Asterisk></label>
            <FullWidthInput name="consentPhone" placeholder="Consent User Phone Number" value={formData.consentPhone} error={errors.consentPhone} onChange={handleChange} />
            {errors.consentPhone && <ErrorMessage>Please fill this field</ErrorMessage>}
          </InputDivWide>
        </Grid>

      </ModalContent>
      <Buttons>
        <Button text="Cancel" width="full" appearance="alt1" onClick={onClose} />
        <Button text="Save Details" width="full" appearance="primary" onClick={handleSubmit} />
      </Buttons>


    </ModalOverlay>

  );
};

export default EditMicrosoftDataForm;


