import { DiscountDataProps } from '@/components/pageBuilder/Sections/SWBasket/BasketDiscount/BasketDiscount.types';
import { InitialState, ModalTypes } from '@/hooks/modal/types';
import { DiscountCodeProps, ProductBasketDiscount, applyDiscountCode } from '@/services/discount.services';
import React, { createContext, useState } from 'react';

type DiscountContextProps = {
  discountData: DiscountDataProps | null;
  loading: boolean;
  applyDiscount: (
    discountCode: string,
    products: ProductBasketDiscount[],
    csrfToken: string,
    setErrorInfo: any,
    toggleModal: any
  ) => Promise<{ success: boolean; data?: any; statusCode?: number }>;
  error: string | null;
  clearDiscount: () => void;
};

export const DiscountContext = createContext<DiscountContextProps | undefined>(undefined);

export const DiscountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [discountData, setDiscountData] = useState<DiscountDataProps | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const applyDiscount = async (
    discountCode: string,
    products: ProductBasketDiscount[],
    csrfToken: string,
    setErrorInfo: (info: InitialState['errorInfo']) => void,
    toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
  ): Promise<{ success: boolean; data?: any; statusCode?: number }> => {
    const discountPayload: DiscountCodeProps = { discountCode, products };

    try {
      setLoading(true);
      const res = await applyDiscountCode(discountPayload, csrfToken, setErrorInfo, toggleModal);

      if (res?.success) {
        setDiscountData(res.data);
      } else {
        if (res.statusCode === 400) {
          setError('Invalid discount code. Please check and try again.');
        } else if (res.statusCode === 401) {
          setError('Session expired. Cookie unavailable.');
        } else {
          setError('An unexpected error occurred. Please try again later.');
        }
      }
      return res;
    } catch (error) {
      setError('Failed to apply discount code.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }

    return { success: false }; // Ensure the return type is consistent
  };

  const clearDiscount = () => {
    setDiscountData(null);
  };
  return (
    <DiscountContext.Provider value={{ discountData, loading, applyDiscount, clearDiscount, error }}>
      {children}
    </DiscountContext.Provider>
  );
};
