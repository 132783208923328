import { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getImage, getSrc } from 'gatsby-plugin-image';
import { Heading } from '@uk-source-web/heading';
import { Span } from '@uk-source-web/span';
import { Icon } from '@uk-source-web/icon';
import Image from '@legacy-vfuk/core-image';

import {
  UserBasketItem,
  UserBasketItemBase,
  isInBasket,
  removeFromBasket,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { OPCOOptions } from '@/types/Services.types';

import BasketPrice from '../BasketPrice';
import BasketItemDelete from '../BasketItemDelete';
import BasketItemBundle from '../BasketItemBundle';
import { BasketItemProps } from '../SWBasket.types';
import BasketQuantitySelector from '../BasketQuantitySelector';
import { NULL_PRICE_VALUE, getBundleIncludes, getMonthlyPrice, getUpfrontPrice } from '../SWBasket.helper';

import BasketItemStyles, {
  BasketAccordion,
  BasketAccordionButton,
  BasketAccordionButtonWrapper,
  BasketAccordionItem,
  BasketAccordionPanel,
  BasketAccordionPanelWrapper,
  BasketItemContents,
  BasketItemFlag,
  BasketItemGridPrices,
  BasketItemInner,
  BasketItemThumb,
  BasketItemThumbWrapper,
  BasketItemTitle,
  AdditionalData,
  DiscountWrapper,
  BasketItemNormal,



} from './BasketItem.styles';
import handleTranslation from '@/helpers/handleTranslation';
import StateNotification from '@legacy-vfuk/core-state-notification';
import { CurrencyPrice, HeadingPrice, SuffixPrice } from '../BasketPrice/BasketPrice.styles';
import EditMicrosoftDataForm from './MicrosoftDataForm/EditMicrosoftDataForm';

const OPCO = process.env.GATSBY_OPCO;

const BasketItem = ({ discountData, continueBtn,microsoftItemState,microsoftState, ...item }: BasketItemProps & { discountData: { discountData: string | undefined }, continueBtn: React.Dispatch<React.SetStateAction<boolean>>,microsoftItemState:React.Dispatch<SetStateAction<string>>,microsoftState:string }) => {



  const { increaseDecreaseAct = true, quantity } = item;


 //extract discount data and trype
  const discountType = discountData.discountData?.split(":")[0]
  const discountAmount = parseInt((discountData.discountData || "")?.split(":")[1])
  

  // update additional state
 
  const { t } = useTranslation();
  const dispatch = useAppDispatch();


  const userBasket = useAppSelector(state => state.userBasket.data);

  //check if it is a microsoft product or not
  const isMicrosoftProduct = item.msftProduct 



  const [showForm, setShowForm] = useState(false)

  //open additional info section by setting it by true
  const handleEditClick = () => {

    setShowForm(prevState => true)
  }

  const handleSubmit = (data: Record<string, string>) => {
    if (Object.keys(formErrors).length === 0) {
      continueBtn(false)
      microsoftItemState('success'); // Update state to 'success'
    }

    // Close the modal after saving
    setShowForm(false);
  };

  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({});

  //set error object
  const handleErrors = (newErrors: Record<string, boolean>) => {

    setFormErrors(newErrors);
  };

  //acount ID 
  const userSessionData = sessionStorage.getItem("persist:vf_userSession"); //needs to be updated with the correct value which indicates to the account
  let accountId = ""


  if (userSessionData) {
    // Ensure that userSessionData is not null before parsing
    
      const parsedData = JSON.parse(userSessionData);
      accountId = JSON.parse(parsedData.user).userId;
      // accountId = "new_acc"; to test another account
    
  }
 



  const currentItem: UserBasketItem | undefined = userBasket.items.filter(
    currentItem => currentItem.productId === item?.productId
  )[0];

  const bundleIncludes = getBundleIncludes(item);
  const hasBundleIncludes = bundleIncludes?.length > 0;
  const hasUpfrontPrice = OPCO === OPCOOptions.IRELAND;

  const thumbnailIconSrc =
    typeof item.thumbnailIcon == 'string'
      ? item.thumbnailIcon
      : getSrc(item.thumbnailIcon) ?? getImage(item.thumbnailIcon)?.placeholder?.fallback;

  const upFrontPrice = useMemo(() => getUpfrontPrice(currentItem, quantity), [currentItem]);
  const monthlyPrice = useMemo(() => getMonthlyPrice(currentItem), [currentItem]);

  const isUpfrontPriceValid = upFrontPrice !== undefined && !isNaN(Number(upFrontPrice));
  const isMonthlyPriceValid = monthlyPrice !== undefined && !isNaN(Number(monthlyPrice));



  const currentFlag = item.configTab?.toLowerCase().includes('bundle') ? 'Bundle' : item.configTab;
  const quantityTrans = handleTranslation('Quantity', 'Quantidade');
  const monthlyTrans = handleTranslation('Monthly', 'Mensal');

  const handleRemoveItem = () => {
    if (isInBasket(userBasket, item)) {
      dispatch(removeFromBasket(item));
    }
  };

  return (

    <BasketItemStyles hasFlag={!!currentFlag}>
      <BasketItemNormal>

        <BasketItemThumbWrapper>
          <BasketItemThumb hasThumbnail={!!thumbnailIconSrc}>
            <Image alt="image" sm={{ src: thumbnailIconSrc ?? '' }} />
          </BasketItemThumb>
        </BasketItemThumbWrapper>
        <BasketAccordion allowMultiple allowToggle defaultIndex={[0]}>
          <BasketAccordionItem>
            {({ isExpanded }) => (
              <BasketItemInner>
                <BasketItemTitle>
                  <Heading level={4} weight={3} text={item?.dxlInformation?.name ?? item?.name} />
                  {hasBundleIncludes && (
                    <BasketAccordionButtonWrapper isExpanded={isExpanded}>
                      <BasketAccordionButton>
                        <Span id="basket-accordion-details">{t('seeDetails')}</Span>
                        <Icon appearance="secondary" group="system" name="chevron-down" id="basket-accordion-button" />
                      </BasketAccordionButton>
                    </BasketAccordionButtonWrapper>
                  )}
                </BasketItemTitle>
                <BasketItemContents>
                  <BasketQuantitySelector
                    item={{ increaseDecreaseAct, quantity, ...currentItem }}
                    label={quantityTrans}
                  />

                  <BasketItemGridPrices hasUpfrontPrice={hasUpfrontPrice}>
                    {hasUpfrontPrice && discountAmount > 0 && isUpfrontPriceValid ? (
                      <DiscountWrapper>
                        <BasketPrice
                          label={t('upfront')}
                          value={checkForZeroOrNull(upFrontPrice, NULL_PRICE_VALUE)}
                          hasDiscount={true}
                          isNormal={false}
                        />
                        <BasketPrice
                          label={t('upfront')}
                          value={checkForZeroOrNull(upFrontPrice, NULL_PRICE_VALUE)}
                          hasDiscount={false}
                          isNormal={false}
                          discount={discountAmount}
                          discountType={discountType}
                        />
                      </DiscountWrapper>
                    ) : (
                      <BasketPrice
                        label={t('upfront')}
                        value={checkForZeroOrNull(upFrontPrice, NULL_PRICE_VALUE)}
                        isNormal={true}
                      />
                    )}

                    {hasUpfrontPrice && discountAmount > 0 && isUpfrontPriceValid ? (
                      // If upfront discount applied, show monthly price without a discount
                      <BasketPrice
                        label={monthlyTrans}
                        value={checkForZeroOrNull(monthlyPrice * (quantity || 1), NULL_PRICE_VALUE)}
                        isNormal={true}
                      />
                    ) : discountAmount > 0 && isMonthlyPriceValid ? (
                      <DiscountWrapper>
                        <BasketPrice
                          label={monthlyTrans}
                          value={checkForZeroOrNull(monthlyPrice * (quantity || 1), NULL_PRICE_VALUE)}
                          hasDiscount={true}
                          isNormal={false}
                        />
                        <BasketPrice
                          label={monthlyTrans}
                          value={checkForZeroOrNull(monthlyPrice * (quantity || 1), NULL_PRICE_VALUE)}
                          hasDiscount={false}
                          isNormal={false}
                          discount={discountAmount}
                          discountType={discountType} />
                      </DiscountWrapper>
                    ) : (
                      <BasketPrice
                        label={monthlyTrans}
                        value={checkForZeroOrNull(monthlyPrice * (quantity || 1), NULL_PRICE_VALUE)}
                        isNormal={true}
                      />
                    )}
                  </BasketItemGridPrices>
                  <BasketItemDelete handleDelete={handleRemoveItem} />
                </BasketItemContents>
                {hasBundleIncludes && (
                  <BasketAccordionPanelWrapper>
                    <BasketAccordionPanel>
                      <BasketItemBundle bundle={bundleIncludes} />
                    </BasketAccordionPanel>
                  </BasketAccordionPanelWrapper>
                )}
              </BasketItemInner>
            )}
          </BasketAccordionItem>


        </BasketAccordion>
      </BasketItemNormal>

      {
        isMicrosoftProduct &&
        (
          <AdditionalData >
            <StateNotification
              key={microsoftState}
              state={microsoftState}
              heading={{ text: 'Microsoft requires additional information', level: 3 }}
              button={{
                text: 'Edit',
                onClick: handleEditClick,
              }}
            >
              {localStorage.getItem(`${accountId}_microsoftAdditionalData`) !== 'success'&& (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                  There is missing or incorrect information
                </div>
              )}
            </StateNotification>
              {showForm && <EditMicrosoftDataForm onSubmit={handleSubmit} isOpen={showForm} setMicrosoftAdditionalData={microsoftItemState} onErrors={handleErrors} onClose={() => setShowForm(false) } continueBtn = {continueBtn} accountId={accountId} />}
          </AdditionalData>
        )
      }
    </BasketItemStyles>

  );
};

export default BasketItem;
