import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { Span } from '@uk-source-web/span';

const BasketPriceStyles = styled.div`
  padding: ${rem('5px')};
  display: flex;
  flex-direction: column;

  h4 {
    color: var(--mineShaft);
  }
`;

export const LabelPrice = styled(Span)<{ labelAlign?: 'left' | 'right' | 'center' }>`
  font-family: 'VodafoneLight';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }

  ${({ labelAlign }) => {
    switch (labelAlign) {
      case 'left':
        return css`
          text-align: left;
        `;
      case 'right':
        return css`
          text-align: right;
        `;
      case 'center':
        return css`
          text-align: center;
        `;
    }
  }}
`;

export const HeadingPrice = styled.div<{ redColor: boolean; normal: boolean }>`
  display: flex;
  margin-right: 0.4rem;

  ${normal => normal && 'color:black;'}

  > h4,span {
    ${({ redColor, normal }) =>
      redColor &&
      !normal &&
      `
      color: red;
      
    `}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    > h4 {
      font-size: ${rem('24px')};
    }
  }
`;

export const PriceValue = styled.span<{ strikeThrough: boolean }>`
  font-size: ${rem('24px')};
  color: ${({ strikeThrough }) => (strikeThrough ? 'gray' : 'inherit')};
  text-decoration: ${({ strikeThrough }) => (strikeThrough ? 'line-through' : 'none')};
`;

export const DiscountText = styled.span`
  width: 100%;
  font-size: ${rem('18px')};
  font-weight: 200;
`;

export const CurrencyPrice = styled.span`
  font-size: ${rem('24px')};
`;

export const SuffixPrice = styled.span`
  font-family: 'VodafoneLight';
  margin-left: ${rem('2px')};
  font-size: ${rem('15px')};
  font-weight: 200;
`;

export default BasketPriceStyles;
