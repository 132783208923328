import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
`;

export const Asterisk = styled.span`
  font-size: ${rem('12.8px')}; /* Adjust size relative to the label text */
  vertical-align: text-top; /* Lift the asterisk */
`;

export const ModalContent = styled.div`
  background: #fff;
  padding: ${rem('12.8px')};
  width: 90%;  
  max-width: ${rem('480px')};  
  border-radius: ${rem('8px')};
  max-height: 60vh;
  position: relative;
  overflow-y: auto;
`;

export const ModalHeader = styled.div`
  font-size: ${rem('24px')};
  font-weight: bold;
  margin-bottom: ${rem('20px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  > p {
    font-size: ${rem('24px')};
  }
`;

export const CloseButton = styled.button`
  color: gray;
  background: none;
  padding: 0 ${rem('6.4px')};
  border: none;
  font-size: ${rem('24px')};
  cursor: pointer;
  color: gray;

  &:hover {
    color: #ff0000; 
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem('9.6px')};

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;

export const InputDiv = styled.div`
  width: 100%;
`;

export const InputDivWide = styled.div`
  grid-column: span 2;
  width: 100%;
`;

export const Input = styled.input`
  padding: ${rem('8px')};
  width: 100%;
  margin-bottom: ${rem('16px')};
  border: ${rem('1px')} solid #ddd;
  border-radius: ${rem('4px')};
`;

export const ErrorInput = styled(Input)`
  border: ${rem('1px')} solid red;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: ${rem('12.8px')};
  margin-top: ${rem('-16px')};
  margin-bottom: 0;
  display: block;
`;

export const Select = styled.select`
  padding: ${rem('8px')};
  width: 100%;
  margin-bottom: ${rem('32px')};
  border: ${rem('1px')} solid #ddd;
  border-radius: ${rem('4px')};
  appearance: none;
  background: white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="gray" d="M7 10l5 5 5-5H7z"/></svg>'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right ${rem('10px')} center;
  background-size: ${rem('16px')};
`;

export const FullWidthInput = styled(Input)<{error?: boolean}>`
  grid-column: span 2;
  border: ${rem('1px')} solid ${({ error }) => (error ? 'red' : '#ccc')};
`;

export const ButtonCancel = styled.button`
  padding: ${rem('10px')} ${rem('20px')};
  margin: ${rem('5px')};
  border: ${rem('1px')} solid gray;
  border-radius: ${rem('4px')};
  cursor: pointer;
`;

export const Buttons = styled.div`
  display: flex;
  gap: ${rem('19.2px')};
  justify-content: center;
  align-items: center;
  width: 90%;  
  max-width: ${rem('480px')}; 
  position: sticky;
  /* bottom: ${rem('160px')}; */
  bottom: 18%;
  background-color: white;
  border-top: ${rem('0.8px')} solid gray;
  border-radius: ${rem('4.8px')};
  padding: ${rem('19.2px')};
  z-index: 10;
  
  @media (max-width: 450px) {
    flex-direction: column;
    /* bottom: ${rem('56px')}; */
    /* bottom: 80px; */
  }
`;
