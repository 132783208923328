import { SimpleGrid } from '@uk-source-web/simple-grid';
import React, { useEffect, useState } from 'react';
import {
  ContainerWrapper,
  DiscountContainer,
  DiscountHelperTextWrapper,
  DiscountWrapper,
  ParagraphWrapper,
} from './BasketDiscount.styles';
import { Paragraph } from '@uk-source-web/paragraph';
import { TextInputWithLabel, TextInputWithLabelProps } from '@uk-source-web/text-input-with-label';
import { Button } from '@uk-source-web/button';
import { LinkWithIcon } from '@uk-source-web/link-with-icon';
import { Spacer } from '@/components/Global/RegisterAccount/RegisterAccount.styles';
import { Link } from '@uk-source-web/link';
import BasketMoreInformationContent from './BasketMoreInformationContent/BasketMoreInformationContent';
import { useDiscount } from '@/hooks/useDiscount';
import { useAppSelector } from '@/store';
import { ProductBasketDiscount } from '@/services/discount.services';
import { useModalContext } from '@/hooks/modal/useModal';
import { getCurrencySymbol } from '@/helpers/findPrices';

const BasketDiscount = ({ shouldShowDiscountInput = true }: { shouldShowDiscountInput: boolean }) => {
  const [discountCode, setDiscountCode] = useState<string>('');
  const [storedValue, setStoredValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [state, setState] = useState<TextInputWithLabelProps['state']>(undefined);
  const { discountData, applyDiscount, loading, clearDiscount } = useDiscount();
  const { user } = useAppSelector(({ userSession }) => userSession);
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const { setErrorInfo, toggleModal } = useModalContext();
  const totalNumberOfItems = discountData?.itemDiscount?.reduce((acc, item) => acc + (item?.numberOfItems || 0), 0);
  const totalQuantity = userBasket?.items?.reduce((acc, item) => acc + (item.quantity || 0), 0);
  const percentageValue = discountData?.discountValue
    ? parseFloat(discountData?.discountValue.split(':')[1]?.trim().replace('%', '') || '0')
    : 0;
  const totalItemDiscountAmount = (
    discountData?.subtotal && discountData?.dueNow ? discountData?.subtotal - discountData?.dueNow : 0
  ).toFixed(2);

  useEffect(() => {
    const value = sessionStorage.getItem('myInput');
    if (value) {
      setStoredValue(value);
    }
  }, []);

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedDiscountValue = e.target.value.trim();
    setDiscountCode(trimmedDiscountValue);
    handleValidateInput(trimmedDiscountValue);
  };
  const handleDiscountCode = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // Save the input value to session storage
    sessionStorage.setItem('myInput', JSON.stringify(discountCode));
    setStoredValue(discountCode);

    if (storedValue === discountCode) {
      setErrorMessage('Discount code already applied.');
      setState('warn');
      setDiscountCode('');
      return;
    }

    const products = userBasket.items.map(item => ({
      id: item.productId,
      price: item?.dxlInformation?.price[0]?.price || 0,
      numberOfUnits: item?.quantity ?? 1,
    })) as ProductBasketDiscount[];
    const result = await applyDiscount(discountCode, products, user?.csrfToken || '', setErrorInfo, toggleModal);
    console.log('result', result);
    if (result?.success) {
      setDiscountCode('');
      setState(undefined);
    } else if (result?.statusCode === 401) {
      setErrorMessage('Session expired.');
      setState('warn');
    } else {
      setErrorMessage(`The code ${discountCode} does not exist or is expired.`);
      setState('error');
    }
  };

  const handleIconClick = () => {
    clearDiscount();
    setDiscountCode('');
    sessionStorage.setItem('myInput', '');
    setStoredValue('');
  };

  const handleValidateInput = (value: string) => {
    if (value.trim() === '') {
      setErrorMessage('Discount code cannot be empty.');
      setState('error');
    } else if (value.length < 5) {
      setErrorMessage('Discount code must have 5 char.');
      setState('error');
    } else {
      setErrorMessage('');
      setState('success');
    }
  };
  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleValidateInput(e.target.value);
  };

  return (
    <SimpleGrid
      columns={{
        sm: 1,
        md: 1,
      }}
      verticalAlign="stretch"
      justify="center"
    >
      <ContainerWrapper>
        {shouldShowDiscountInput && (
          <>
            <DiscountWrapper errorMessage={errorMessage.length}>
              <TextInputWithLabel
                id="defaultTextInput"
                state={state}
                fieldWrapper={{
                  label: 'Apply a discount code',
                  width: 'full',
                  stateText: errorMessage,
                }}
                textInput={{
                  id: 'discnout',
                  type: 'text',
                  placeholder: 'Enter Code',
                  value: discountCode,
                  onChange: handleDiscountChange,
                  onBlur: handleInputBlur,
                }}
              />
              <Button
                text="Apply"
                appearance="alt1"
                width="auto"
                onClick={handleDiscountCode}
                loading={loading}
                state={discountCode === '' || state === 'error' || state === 'warn' ? 'disabled' : undefined}
              />
            </DiscountWrapper>
            {discountData?.discountName && (
              <DiscountHelperTextWrapper>
                <div>
                  <Paragraph size={1} weight={2}>
                    {discountData?.discountName}
                  </Paragraph>
                  <Paragraph size={1} weight={2}>
                    Applied to {totalQuantity} item{totalNumberOfItems !== 1 ? 's' : ''} - {getCurrencySymbol()}
                    {totalItemDiscountAmount}
                  </Paragraph>
                </div>
                <LinkWithIcon
                  appearance="secondary"
                  text=""
                  icon={{
                    name: 'close',
                    group: 'system',
                  }}
                  onClick={handleIconClick}
                  srText="Screen reader text!"
                />
              </DiscountHelperTextWrapper>
            )}
          </>
        )}
      </ContainerWrapper>
      <DiscountContainer>
        <BasketMoreInformationContent />
        <Spacer h={30} />
        {shouldShowDiscountInput && (
          <>
            <ParagraphWrapper>
              <Paragraph size={1} weight={1}>
                {' '}
                By proceeding and clicking on “Continue” you are accepting the&nbsp;
              </Paragraph>
              <Link
                htmlAttributes={{ target: '_blank' }}
                href="https://n.vodafone.ie/content/dam/gowingmyself/pdfs/vbu-pdfs-2023/Vodafone%20Business%20Marketplace%20Terms-%20as%20of%20February%202023.pdf"
                text="Terms and Conditions"
                showUnderline={true}
                size={1}
              />
              <Paragraph size={1} weight={1}>
                &nbsp;and&nbsp;
              </Paragraph>
              <Link
                htmlAttributes={{ target: '_blank' }}
                href="https://n.vodafone.ie/privacy.html"
                text="Privacy Supplement current"
                showUnderline={true}
                size={1}
              />
            </ParagraphWrapper>
            <Spacer h={20} />
          </>
        )}
      </DiscountContainer>
    </SimpleGrid>
  );
};

export default BasketDiscount;
