import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const SWBakestTotalPricesStrip = styled.div`
  display: flex;
  > * {
    margin-right: ${rem('80px')};
    &:last-child {
      margin-right: ${rem('50px')};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    width: 100%;
    justify-content: flex-start;
    align-self: flex-end;

    [class*='HeadingPrice'] h4 {
      font-size: ${rem('24px')};
    }

    > * {
      margin-right: ${rem('30px')};
      &:last-child {
        margin-right: ${rem('0px')};
      }
    }
  }
`;
