import { IFormInput } from '@/components/pageBuilder/Elements/Form/Form.types';
import { GetAddressType } from '@/services/address.services';
import { addressPickerEircodeName } from './ContactFormConfig';
import { addressFormConfig } from './AddressConfig';

export const companyDetailsFormConfig: IFormInput[] = [
  {
    inputType: 'select',
    label: 'Company size',
    name: 'companySize',
    placeholder: 'Select',
    requiredField: true,
    fullWidth: false,
    customValue: 'SOHO',
    options: [{ text: 'Select size', value: 'select' }],
  },
  {
    inputType: 'inputField',
    name: 'companyName',
    label: 'Company name',
    fullWidth: false,
    requiredField: true,
    lengthValidationSize: 2,
    placeholder: 'Company name',
    validationType: 'length',
  },
  {
    inputType: 'select',
    label: 'Industry Type',
    name: 'industryType',
    placeholder: 'Industry Type',
    fullWidth: false,
    customValue: 'teste',
    options: [{ text: 'Select industry', value: 'select' }],
  },
  {
    inputType: 'select',
    label: 'Business type',
    placeholder: 'Type of Business',
    name: 'typeOfBusiness',
    fullWidth: false,
    requiredField: true,
    customValue: 'limitedCompany',

    options: [{ text: 'Select type', value: 'select' }],
  },
  {
    inputType: 'inputField',
    name: 'companyRegistrationNumber',
    label: 'Company Registration number',
    fullWidth: true,
    requiredField: false,
    hidden: true,
    validationType: 'length',
    placeholder: 'Registration number',
  },
  {
    inputType: 'radioList',
    name: 'AddressOption',
    label: 'Address or Eircode search option',
    fullWidth: true,
    customLabelSize: '18px',
    radioOptions: [
      { label: 'Address', value: 'TEXT' as GetAddressType },
      { label: 'Eircode', value: 'POSTAL_CODE' as GetAddressType },
    ],
  },
  {
    inputType: 'addressPicker',
    name: 'address',
    label: 'Search Your Address',
    alternativeLabel: 'Select your address',
    customErrorMessage: 'Please enter a valid address',
    fullWidth: true,
    disabled: true,
    placeholder: 'Type here your address',
    helpText: 'Search for your address and select from the results',
    validationType: 'length',
    dataType: 'alphanum-space',
    hidden: true,
  },
  {
    inputType: 'addressPicker',
    name: 'eircodeOption',
    label: addressPickerEircodeName,
    helpText: 'Start typing your Eircode and select',
    fullWidth: true,
    disabled: true,
    placeholder: 'Search Eircode',
    validationType: 'postCode',
    dataType: 'alphanum',
    hidden: true,
  },
  {
    inputType: 'checkbox',
    name: 'sameAsContactCheckbox',
    label: 'Same as contact address',
    customFontSize: '18px',
    fullWidth: true,
    labelType: 'hidden',
    validationType: 'length',
    checkboxOptions: [{ label: 'Use the same address filled in contact details', value: 'sameAsContact' }],
  },
  {
    inputType: 'checkbox',
    name: 'addressCheckbox',
    label: 'Enter your address manually',
    customFontSize: '18px',
    fullWidth: true,
    labelType: 'hidden',
    validationType: 'length',
    checkboxOptions: [{ label: 'Enter your address manually', value: 'addressManually' }],
  },

  ...addressFormConfig,
];
