import React from 'react';
import {
  BasketButtonsInner,
  SWBakestTotalPrices,
  SWBasketContainer,
  SWBasketTotal,
  WrapperBasketButtons,
} from '../SWBasket.styles';
import { Heading } from '@uk-source-web/heading';
import { Button } from '@uk-source-web/button';
import handleTranslation from '@/helpers/handleTranslation';
import { OPCOOptions } from '@/types/Services.types';
import { checkForZeroOrNull } from '@/helpers/nullOrZero';
import { useAppSelector } from '@/store';
import { SWBasketStripProps } from '../SWBasket.types';
import { useTranslation } from 'react-i18next';
import BasketMoreInformation from './BasketMoreInformation/BasketMoreInformation';
import { useDiscount } from '@/hooks/useDiscount';
import BasketPriceStrip from './BasketPriceStrip';
import { SWBakestTotalPricesStrip } from './BasketPriceStrip/BasketStrip.styles';

const OPCO = process.env.GATSBY_OPCO;

const BasketStrip = ({
  continueShoppingButton,
  requestCallbackButton,
  goToCheckoutButton,
  postProductOrder,
  disableContinueButton,
  hideContinueShoppingButton,
}: SWBasketStripProps) => {
  const hasUpfrontPrice = OPCO === OPCOOptions.IRELAND;
  const { discountData } = useDiscount();

  const { t } = useTranslation();
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const montlyCostValue = checkForZeroOrNull(userBasket?.total?.monthly?.gross, 'NA');
  const calcMonthlyDiscount = userBasket?.total?.monthly?.gross;
  const calcUpfrontDiscount = userBasket?.total?.upfront?.gross;
  const percentageValue = discountData?.discountValue
    ? parseFloat(discountData?.discountValue.split(':')[1]?.trim().replace('%', '') || '0')
    : 0;
  const upfrontPriceAfterDiscount = discountData ? discountData.dueNow : calcUpfrontDiscount;
  const monthlyPriceAfterDiscount = discountData ? discountData.recurringCharges : calcMonthlyDiscount;

  const checkMontlyCostValue =
    montlyCostValue === 'NA' ? 'NA' : discountData ? monthlyPriceAfterDiscount : userBasket?.total?.monthly?.gross;

  const upfrontCostValue = checkForZeroOrNull(userBasket.total?.upfront?.gross, 'NA');
  const checkUpfrontCostValue =
    upfrontCostValue === 'NA' ? 'NA' : discountData ? upfrontPriceAfterDiscount : userBasket?.total?.upfront?.gross;
  const totalCost = handleTranslation('Total cost', 'Valor total');
  const monthlyTrans = handleTranslation('Monthly', 'Mensal');
  const completePurchase = handleTranslation('Complete purchase', 'Completar compra');

  

  return (
    <SWBasketContainer backgroundColor="#FFFFFF">
      <SWBasketTotal>
        <Heading level={4} weight={3}>
          {totalCost}
        </Heading>
        <SWBakestTotalPricesStrip>
          {hasUpfrontPrice && <BasketPriceStrip label={t('upfront')} labelAlign="left" value={checkUpfrontCostValue} />}
          <BasketPriceStrip label={monthlyTrans} labelAlign="left" value={checkMontlyCostValue} />
        </SWBakestTotalPricesStrip>
      </SWBasketTotal>
      <BasketMoreInformation />
      <WrapperBasketButtons>
        <BasketButtonsInner>
          {!hideContinueShoppingButton && OPCO !== OPCOOptions.PORTUGAL && (
            <Button
              text={continueShoppingButton.label}
              appearance="secondary"
              href={continueShoppingButton.url}
              data-selector="MS365-BundleConfig-OrderSummary-Next"
            />
          )}
          {requestCallbackButton && (
            <Button
              text={requestCallbackButton.label}
              appearance="secondary"
              href={requestCallbackButton?.url}
              data-selector="MS365-BundleConfig-OrderSummary-RequestCallback"
            />
          )}
        </BasketButtonsInner>
        <Button
          id="basket-button-complete-purchase"
          state={userBasket && userBasket.items?.length > 0 && !disableContinueButton ? undefined : 'disabled'}
          text={completePurchase}
          appearance={goToCheckoutButton.rank}
          onClick={postProductOrder}
        />
      </WrapperBasketButtons>
    </SWBasketContainer>
  );
};

export default BasketStrip;
