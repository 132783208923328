module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-apollo@4.0.3_yqulbin2w5njvex4boz7a5tet4/node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://graphql.contentful.com/content/v1/spaces/agqck2hr2zli/environments/maac-dev","headers":{"authorization":"Bearer __JV6d8SpmQAX0OwGJ8aokXkPKAfQaKiD9xBX4WH1ww"}},
    },{
      plugin: require('../../../node_modules/.pnpm/@chakra-ui+gatsby-plugin@3.1.3_vjdmw6cgraunuc7wjf7kt2pdxy/node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":false,"isUsingColorMode":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.14.0_clxmc7v2mz7mh3lbpcjj4fryha/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":true,"displayName":true,"fileName":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.14.0_gatsby@5.10.0/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://dev.marketplaceshowhome.vodafone.com"},
    },{
      plugin: require('../plugins/og-url-generator/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://dev.marketplaceshowhome.vodafone.com"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_x42bkahe5mdcdwiygojgt6b2ha/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/img_vodafone__icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7e1719cd72873eeb75c99049929a9f60"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.10.0_sq25aybyathbkmlvolgloulw4m/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
