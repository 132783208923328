import { useTranslation } from 'react-i18next';
import { Heading } from '@uk-source-web/heading';
import formatCurrency from '@/helpers/formatCurrency';

 

import handleTranslation from '@/helpers/handleTranslation';
import { NULL_PRICE_VALUE } from '../../SWBasket.helper';
import BasketPriceStylesStrip, { CurrencyPriceStrip, HeadingPriceStrip, LabelPriceStrip, SuffixPriceStrip } from './BsketPriceStrip.styles';
 
interface BasketPriceProps {
  label: string;
  labelAlign?: 'left' | 'right' | 'center';
  value?: number | string;
}
 
const BasketPriceStrip = ({ label, labelAlign, value }: BasketPriceProps) => {
  const { t } = useTranslation();
  const priceSuffixTrans = handleTranslation("ex.VAT","sem IVA")
 
  const formatValuePrice = (value: number | string) => {
    const possiblyNumber = String(value).split('.').length > 1;
    const isNullValue = String(value) === NULL_PRICE_VALUE;
 
    if (possiblyNumber || !isNullValue) {
      const numberValue = typeof value === 'number' ? value : Number(value);
      return formatCurrency(numberValue);
    }
 
    return value;
  };
 
  return (
    <BasketPriceStylesStrip>
      <LabelPriceStrip labelAlign={labelAlign}>{label}</LabelPriceStrip>
      <HeadingPriceStrip>
        <Heading level={4} size={2} weight={3}>
          <CurrencyPriceStrip>&euro; </CurrencyPriceStrip>
          {value && formatValuePrice(value)}
          <SuffixPriceStrip> / ({priceSuffixTrans})</SuffixPriceStrip>
        </Heading>
      </HeadingPriceStrip>
    </BasketPriceStylesStrip>
  );
};
 
export default BasketPriceStrip;