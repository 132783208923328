import { TranslationOptions } from '../type';

export default {
  selectedLang: 'Português',
  Lang: 'Selecione sua lingua',
  AddToBasket: 'Aderir',
  UpdateBasket: 'Atualizar Adesão',
  RemoveFromBasket: 'Remover',
  ConfigureYourPack: 'Configurar seu pacote',
  nif: 'NIF',
  account: 'Conta',
  pricingRedSentenceTrendMicro:
    '*Apenas um tipo de edição do Trend Micro poderá ser subscrito (sem limite de número de licenças).',
  pricingRedSentence:
    '* Os preços apresentados são válidos por licença e por mês, e implicam um compromisso de permanência de 12, 24 ou 36 meses.',
  priceSuffix: 'sem IVA',
  pricingBlackSentence:
    'Ao escolher "Aderir" irá ter acesso, através de login com as suas credenciais My Vodafone, à Vodafone Cloud Store. Consulte ',
  and: 'e',
  termsAndConditions: 'Termos e Condições',
  privacySupplement: 'Suplemento de Privacidade',
  billingPlataform: {
    blocklist: {
      title: 'Infelizmente não é possível avançar',
      description: 'Por favor entre em contacto com o seu gestor de comunicações ou ligue 16914.',
      buttonCTA: 'Voltar',
    },
    noAccounts: {
      title: 'Sem contas Vodafone elegíveis para avançar',
      description:
        'Não foram encontradas contas Vodafone, associadas ao seu acesso My Vodafone, que permitam avançar com esta subscrição. Por favor valide as contas associadas na sua área My Vodafone. Em caso de dúvida, por favor entre em contacto com o seu gestor de comunicações ou ligue 16914.',
      buttonCTA: 'Ir para área My Vodafone',
    },
  },
  totalCost: 'Valor total',
  monthly: 'Mensal',
  upfront: 'À vista',
  currency: '€',
  skipToBasket: 'Ir para o carrinho',
  goToBasket: 'Finalizar compra',
  goToCheckout: 'Finalizar compra',
  completePurchase: 'Completar compra',
  nextOption: 'Próxima opção',
  quantity: 'Quantidade',
  yourOrderSummary: 'Carrinho de compras',
  selectProduct: 'Selecionar Produto',
  checkoutLoading: 'Por favor aguarde enquanto processamos o seu pedido ...',
  basketURL: '/marketplace/product-list/basket',

  emptyBasket: 'O seu carrinho está atualmente vazio!',
  emptyBasketSubtitle: 'Antes de processamos o seu pedido, deve adicionar alguns produtos ao seu carrinho de compras.',
  seeDetails: 'Ver detalhes',
} as TranslationOptions;
