import { Span } from '@uk-source-web/span';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const QuantitySelectorStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: ${rem('5px')};
  margin-top: 1.2rem;
  margin-left: ${rem('15px')};
  margin-right: ${rem('20px')};

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    grid-column: 1;
    grid-row: 2;
    margin-left: 0;
    align-items: center;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;
  }
`;

export const QuantitySelectorLabel = styled(Span)`
  font-family: 'VodafoneLight';
  text-align: flex-start;
  margin-bottom: ${rem('10px')};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    text-align: left;
    margin-bottom: 0;
  }
`;

export const QuantitySelectorActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem('36px')};

  > button {
    background: #fff;
    border: ${rem('2px')} solid #000;
    height: ${rem('30px')};
    width: ${rem('30px')};

    svg,
    path {
      stroke: #000 !important;
    }

    &:hover {
      background: #d8d8d8;
    }
  }
`;

export const QuantitySelectorValue = styled(Span)`
  font-family: 'VodafoneLight';
  text-align: center;
  border: 0.1rem solid #4a4d4e26;
  border-radius: ${rem('6px')};
  padding: 0.2rem 0.8rem;
  background-color: white;
  margin: 0 ${rem('14px')};
  width: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }
`;

export default QuantitySelectorStyles;
