import { DiscountContext } from "@/contexts/DiscountContext/DiscountContext";
import { useContext } from "react";

// Custom hook
export const useDiscount = () => {
    const context = useContext(DiscountContext);
    if (context === undefined) {
      throw new Error('useDiscount must be used within a DiscountProvider');
    }
    return context;
  };