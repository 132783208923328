import { useTranslation } from 'react-i18next';
import { Heading } from '@uk-source-web/heading';
import formatCurrency from '@/helpers/formatCurrency';
import { NULL_PRICE_VALUE } from '../SWBasket.helper';

import BasketPriceStyles, {
  CurrencyPrice,
  DiscountText,
  HeadingPrice,
  LabelPrice,
  PriceValue,
  SuffixPrice,
} from './BasketPrice.styles';
import handleTranslation from '@/helpers/handleTranslation';
import { space } from '@chakra-ui/react';
import { Span } from '@uk-source-web/span';

interface BasketPriceProps {
  label: string;
  labelAlign?: 'left' | 'right' | 'center';
  value?: number | string;

  hasDiscount?: boolean;
  isNormal?: boolean;
  discount?: number;
  discountType?: string;
}

const BasketPrice = ({ label, labelAlign, value, hasDiscount, discount, isNormal, discountType }: BasketPriceProps) => {
  // calculate discount in different states

  const discountResult = (value: number | string, discount?: number, discountType?: string) => {

    //if there is no discount
    if (value === 'NA') {
      return value;
    }
    // if there is a discount

    const numericValue = typeof value === 'number' ? value : parseFloat(value);

    if (isNaN(numericValue)) {
      return value;
    }

    let newValue = numericValue;

    if (discountType === 'Percentage' && discount && discount > 0) {
      newValue = numericValue * (1 - discount / 100);
    } else if (discountType === 'Fixed amount' && discount && discount > 0) {
      newValue = numericValue - discount;
    }

    // Ensure the discounted value doesn't go below 0
    return newValue < 0 ? 0 : newValue;
  };

  const { t } = useTranslation();
  const priceSuffixTrans = handleTranslation('(ex.VAT)', 'sem IVA');

  const formatValuePrice = (value: number | string) => {
    const possiblyNumber = String(value).split('.').length > 1;
    const isNullValue = String(value) === NULL_PRICE_VALUE;

    if (possiblyNumber || !isNullValue) {
      let numberValue = typeof value === 'number' ? value : Number(value);
      return formatCurrency(numberValue);
    }

    return value;
  };

  const discountedValue = discountResult(value, discount, discountType);

  return (
    <BasketPriceStyles>
      {typeof discount == 'undefined' && <LabelPrice labelAlign={labelAlign}>{label}</LabelPrice>}

      <>
        {discountedValue != 'NA' ? (
          <HeadingPrice redColor={!hasDiscount} normal={isNormal}>
            <Heading level={4} size={2} weight={3}>
              <CurrencyPrice>&euro;</CurrencyPrice>
              <PriceValue strikeThrough={hasDiscount}>
                {discountedValue && formatValuePrice(discountedValue)}
              </PriceValue>

              <SuffixPrice>/{priceSuffixTrans}</SuffixPrice>
              {!hasDiscount && !isNormal && discountType == 'Percentage' && (
                <DiscountText>-{discount}%OFF</DiscountText>
              )}
              {!hasDiscount && !isNormal && discountType == 'Fixed amount' && (
                <DiscountText>-{discount}&euro;OFF</DiscountText>
              )}
            </Heading>
          </HeadingPrice>
        ) : (
          <Heading level={4} size={2} weight={3}>
            {'-'}
          </Heading>
        )}
      </>
    </BasketPriceStyles>
  );
};

export default BasketPrice;
