import { InitialState, ModalTypes } from '@/hooks/modal/types';
import { api } from './auth.services';
import axios from 'axios';

export type ProductBasketDiscount = {
  id: string;
  price: number;
  numberOfUnits: number;
};
export type DiscountCodeProps = {
  discountCode: string;
  products: ProductBasketDiscount[];
};
export const applyDiscountCode = async (
  productsDiscount: DiscountCodeProps, 
  csrfToken: string, 
  setErrorInfo: (info: InitialState['errorInfo']) => void, 
  toggleModal: (isModalOpen: InitialState['isModalOpen'], modalType?: ModalTypes) => void
) => {
  try {
    const res = await api.post('/discount-code', productsDiscount, {
      headers: { Authorization: csrfToken },
    });

    return  { success: true, data: res.data, statusCode:res.status};
  } catch (error) {
    console.error('error', error);

    let statusCode = 500; // Default to server error if undefined
    if (axios.isAxiosError(error)) {
      statusCode = error.response?.data?.code || error.response?.status || 500;
      setErrorInfo({
        statusType: error.response?.data?.error,
        statusCode,
        requestUrl: 'product-order',
      });
      // toggleModal(true, 'Error');
    }

    return { success: false, statusCode };
  }
};