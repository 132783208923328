import React from 'react';
import { Heading } from '@uk-source-web/heading';
import { Paragraph } from '@uk-source-web/paragraph';
import {
  DiscountHeader,
  DiscountInformationWrapper,
  HorizontailLine,
  RecurringDiscountHeader,
} from '../BasketDiscount.styles';
import { Spacer } from '@/components/Global/RegisterAccount/RegisterAccount.styles';
import { useDiscount } from '@/hooks/useDiscount';
import { useAppSelector } from '@/store';

const BasketMoreInformationContent = () => {
  const { userBasket } = useAppSelector(({ userBasket: { data } }) => ({
    userBasket: data,
  }));
  const { discountData } = useDiscount();

  const monthlyGross = parseFloat(userBasket?.total?.monthly?.gross) || 0;
  const upfrontGross = parseFloat(userBasket?.total?.upfront?.gross) || 0;
  const totalGross = monthlyGross + upfrontGross;

  return (
    <>
      <DiscountInformationWrapper>
        <Paragraph size={1} weight={2}>
          Subtotal
        </Paragraph>
        <Paragraph size={2} weight={2}>
          &euro; {(discountData?.subtotal || totalGross).toFixed(2)}
        </Paragraph>
      </DiscountInformationWrapper>
      {discountData && discountData?.upfrontDiscount !== 0 && (
        <DiscountInformationWrapper>
          <Paragraph size={1} weight={2}>
            Discounts
          </Paragraph>
          <Paragraph size={2} weight={2}>
            -&euro; {(discountData?.upfrontDiscount || 0).toFixed(2)}
          </Paragraph>
        </DiscountInformationWrapper>
      )}
      <DiscountInformationWrapper>
        <Paragraph size={1} weight={2}>
          Estimated VAT
        </Paragraph>
        <Paragraph size={2} weight={2}>
          &euro; {(discountData ? discountData?.estimatedVAT : 0.0).toFixed(2)}
        </Paragraph>
      </DiscountInformationWrapper>
      {upfrontGross > 0 && (
        <>
          <DiscountInformationWrapper>
            <DiscountHeader>
              <Heading size={1} level={3} weight={3} text={'Due now'} />
              <span>Price reflects one-time and prorated recurring charges, if applicable.</span>
            </DiscountHeader>
            <Paragraph size={2} weight={3}>
              &euro; {(discountData ? discountData?.dueNow : totalGross).toFixed(2)}
            </Paragraph>
          </DiscountInformationWrapper>
          <Spacer h={15} />
        </>
      )}
      {monthlyGross > 0 && upfrontGross > 0 && (
        <>
          <HorizontailLine />
          <Spacer h={20} />
        </>
      )}
      {monthlyGross > 0 && (
        <>
          <DiscountInformationWrapper>
            <DiscountHeader>
              <Heading size={1} level={3} weight={3} text={'Estimated recurring charges'} />
              <Paragraph size={1} weight={1}>
                Due monthly (excluding tax)
              </Paragraph>
            </DiscountHeader>
            <Paragraph size={2} weight={3}>
              &euro; {(discountData ? discountData?.recurringCharges : monthlyGross).toFixed(2)}
            </Paragraph>
          </DiscountInformationWrapper>
          <Spacer h={20} />
          {discountData && (
            <DiscountInformationWrapper>
              <RecurringDiscountHeader>
                <Heading size={1} level={3} text={'Recurring discounts'} />
                {discountData?.billingCycles &&
                  discountData?.billingCycles !== 'UNLIMITED' &&
                  discountData?.billingCycles !== '0' && (
                    <Paragraph size={1} weight={1}>
                      {discountData?.billingCycles} billing cycles
                    </Paragraph>
                  )}
              </RecurringDiscountHeader>
              <Paragraph size={1} weight={2}>
                -&euro; {(discountData ? discountData?.recurringDiscount : 0.0).toFixed(2)}
              </Paragraph>
            </DiscountInformationWrapper>
          )}
        </>
      )}
    </>
  );
};

export default BasketMoreInformationContent;
