import styled from '@emotion/styled';
import { rem } from 'polished';

interface ThemeProps {
  invertTheme?: boolean;
}

export const CreditVettingWrapper = styled.div<ThemeProps>`
  width: 100%;
  display: flex;
  background-color: ${({ invertTheme }) => (invertTheme ? '#f4f4f4' : '#222')};
  align-items: center;
  justify-content: center;
  color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
  flex-direction: column;
  font-family: 'VodafoneLight';
  height: ${rem('450px')};
`;

export const CVFDescription = styled.div<ThemeProps>`
  display: flex;
  font-size: ${rem('20px')};
  justify-content: center;
  align-items: center;
  width: ${rem('780px')};
  max-width: 90%;
  font-weight: 400;
  text-align: center;

  color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: start;
  }
`;

export const ElementWrapper = styled.div<ThemeProps>`
  width: ${rem('469px')};
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  [class*='FieldWrapperstyle__Label'] {
    color: ${({ invertTheme }) => (invertTheme ? '#000' : '#fff')};
  }
  [class*='SelectInputWithLabelstyle__Container'] {
    width: 100%;
  }
`;

export const InlineNotificationWrapper = styled.div`
  width: ${rem('628px')};
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem('10px')};
  #cvf-inline-notification {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${rem('10px')};
    height: ${rem('42px')};
    margin: ${rem('6px')} 0;
    font-weight: 600;
    svg path {
      fill: #f06666;
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
      height: ${rem('68px')};
    }
  }
`;
