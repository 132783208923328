import { HorizontalStepper } from '@uk-source-web/horizontal-stepper';
import { SWBasketContainer, SWHorizontalStepper } from '../SWBasket.styles';

export const BasketHorizontalStepper = ({ currentStep }: { currentStep: number }) => {
  const steps = [
    {
      title: 'Basket',
      srText: 'Stepper 1',
    },
    {
      title: 'Review',
      srText: 'Stepper 2',
    },
    {
      title: 'Order confirmation',
      srText: 'Stepper 3',
    },
  ];
  return (
    <SWHorizontalStepper currentStep={currentStep}>
      <SWBasketContainer>
        <HorizontalStepper steps={steps} currentStep={currentStep} />
      </SWBasketContainer>
    </SWHorizontalStepper>
  );
};
