import { CollapsibleContainer, CollapsibleContainerBody, CollapsibleContainerHeader } from '@uk-source-web/collapsible-container'
import React from 'react'
import { BasketMoreInformationWrapper } from '../../SWBasket.styles'
import BasketMoreInformationContent from '../../BasketDiscount/BasketMoreInformationContent/BasketMoreInformationContent'

const BasketMoreInformation = () => {
  return (
    <BasketMoreInformationWrapper>
    <CollapsibleContainer appearance='primary'>
    <CollapsibleContainerHeader>
    More information
    </CollapsibleContainerHeader>
    <CollapsibleContainerBody>
     <BasketMoreInformationContent/>
    </CollapsibleContainerBody>
  </CollapsibleContainer>
  </BasketMoreInformationWrapper>
  )
}

export default BasketMoreInformation