import styled from 'styled-components';
import { rem } from 'polished';

interface SWDiscountWrapper {
  errorMessage?: any;
}

export const DiscountWrapper = styled.div<SWDiscountWrapper>`
  display: flex;
  gap: ${rem('15px')};
  justify-content: flex-end;
  align-items: ${props => (props.errorMessage === 0 ? 'end' : 'center')};
  flex-wrap: nowrap;
  [data-component-name='TextInput'] {
    height: ${rem('46px')};
    *:focus-visible {
      box-shadow: none !important;
    }
  }
  margin-bottom: ${rem('20px')};

  [data-component-name='Button'] {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
    min-height: 48px;
    margin-top: ${props => (props.errorMessage === 0 ? `0px` : `10px`)};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    [data-component-name='TextInputWithLabel'] {
      max-width: 26%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-wrap: wrap;
  }
`;
export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DiscountHelperTextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${rem('280px')};
  p {
    margin: 0px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    gap: ${rem('208px')};
    margin-bottom: ${rem('10px')};
    p:last-child {
      font-size: ${rem('12px')};
    }
  }
`;
export const DiscountHeader = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 0px;
  }
  span {
    font-size: ${rem('14px')};
    font-weight: 400;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    span {
      font-size: 11px;
    }
  }
`;
export const DiscountInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    display: none;
  }
`;
export const HorizontailLine = styled.div`
  border-top: ${rem('1px')} solid #33333380;
`;
export const ParagraphWrapper = styled.div`
  display: flex;
  font-weight: 400;
  font-size: ${rem('14px')};
  [data-component-name='Link'] {
    color: #e60000;
    font-weight: 700;
  }
`;

export const RecurringDiscountHeader = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 0px;
    font-size:14px;
    font-weight:400;
  }
  span {
    font-size: ${rem('14px')};
    font-weight: 400;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    span {
      font-size: 11px;
    }
  }
`