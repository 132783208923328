import styled from 'styled-components';
import { rem } from 'polished';

const BasketItemDeleteStyles = styled.div`
  display: flex;
  align-items: center;
  > button {
    border: none;
    border-radius: ${rem('4px')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md - 1}px) {
    position: absolute;
    top:1rem;
    right: 0.6rem;
    padding: 0rem;
    background-color: transparent;
  }
`;

export default BasketItemDeleteStyles;
