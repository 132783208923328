import styled from '@emotion/styled';
import { rem } from 'polished';

export const SuccessPageWrapper = styled.div`
  width: 100%;
  display: flex;
  height: ${rem('660px')};
  background-color: #f4f4f4;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'VodafoneRg';
`;

export const RedCheckmarkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem('120px')};
  height: ${rem('120px')};
`;

export const AbsoluteIcon = styled.div<{ x?: number; y?: number }>`
  position: absolute;
  transform: translate(${props => props.x || 0}px, ${props => props.y || 0}px);
`;

export const SuccessPageTitle = styled.div`
  display: flex;
  font-size: ${rem('40px')};
  justify-content: center;
  font-weight: 700;
  align-items: center;
  text-align: center;
  color: #000;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('28px')};
  }
`;

export const SuccessPageSubtitle = styled.div`
  display: flex;
  font-size: ${rem('28px')};
  text-align: center;
  max-width: 90%;
  justify-content: center;
  font-weight: 400;
  align-items: center;
  color: #000;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }
`;

export const SuccessPageButtonWrapper = styled.div`
  width: ${rem('360px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SuccessPageAlertWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: ${rem('20px')};
  [class*='AlertNotificationstyle__AlertNotification'] {
    width: ${rem('660px')};
    max-width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0 ${rem('15px')};
    margin-top: ${rem('20px')};
  }
  margin-bottom: ${rem('40px')};
`;
