import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { Span } from '@uk-source-web/span';

const BasketPriceStylesStrip = styled.div`
  padding: ${rem('5px')};
  display: flex;
  flex-direction: column;

  h4,
  span {
    color: var(--mineShaft);
  }
`;

export const LabelPriceStrip = styled(Span)<{ labelAlign?: 'left' | 'right' | 'center' }>`
  font-family: 'VodafoneLight';

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('20px')};
  }

  ${({ labelAlign }) => {
    switch (labelAlign) {
      case 'left':
        return css`
          text-align: left;
        `;
      case 'right':
        return css`
          text-align: right;
        `;
      case 'center':
        return css`
          text-align: center;
        `;
    }
  }}
`;

export const HeadingPriceStrip = styled.div`
  display: flex;
  > h4 {
    font-size: ${rem('28px')};
    font-weight: 700;
  }
`;

export const CurrencyPriceStrip = styled.span`
  font-size: ${rem('28px')};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${rem('28px')};
  }
`;

export const SuffixPriceStrip = styled.span`
  font-family: 'VodafoneLight';
  margin-left: ${rem('2px')};
  font-size: ${rem('20px')};
  font-weight: 400;
`;

export default BasketPriceStylesStrip;
